/* global $ */

$(document).foundation();


// Header Shrink

/*$(document).on("scroll", function(){
	if
	($(document).scrollTop() > 100){
		$(".nav-background").addClass("shrink");
		/*$(".mobile-header h1").addClass("text-fix");
		/*$('.mobile header h1').css('line-height', 0);
	}
	else
	{
		$(".nav-background").removeClass("shrink");
		/*$(".mobile-header h1").addClass("text-fix");
		/*$('.mobile header h1').css('line-height', divHeight+'px')
	}
});*/


//  Preloader



	Royal_Preloader.config({
	    mode           : 'logo',
	    background     : '#fff',
	    showProgress   : true,
	    showPercentage : true,
	    logo: 'images/2x/np-logo@2x.png',
	    logo_size: [250, 250],
	});







// Hide Header on Scroll

function headerFade(){
	$(".header-image").css("opacity", 1 - $(window).scrollTop() / 100);
}

$(window).scroll(function(){
    headerFade();
});


// Position page below the header


function positionContent(){

	$(window).ready(function(){
		var divHeight = $('.mobile-header').height();
		$('.neils-container').css('padding-top', divHeight+'px');
	});

	$(window).on('resize', function(){
		var divHeight = $('.mobile-header').height();
		$('.neils-container').css('padding-top', divHeight+'px');
	});

	$(window).ready(function(){
		var divHeight = $('.mobile-header').height() / 2;
		var textHeight = $('.mobile-header h1').height() / 2;
		$('.mobile-header h1').css('padding-top', divHeight-textHeight+'px');
	});

	$(window).on('resize', function(){
		var divHeight = $('.mobile-header').height() / 2;
		var textHeight = $('.mobile-header h1').height() / 2;
		$('.mobile header h1').css('padding-top', divHeight+'px');
	});
}


$(window).ready(function(){
  positionContent();
});








/*$(window).scroll(function(){
    $(".mobile-header h1").css("padding-top", 1 - $(window).scrollTop() / 100);
});*/







// Icon animation

var barTop = $('#bar-top');
var barMidL = $('#bar-mid-l');
var barMidR = $('#bar-mid-r');
var barBottom = $('#bar-bottom');
var circle = $('#circle');
var menuIcon = $('#menu-icon');

var MenuIconTimeline = new TimelineLite({
	paused:true,
});

TweenLite.defaultEase = Expo.easeInOut; // Change the default easing

MenuIconTimeline
	.to(barTop, 0.2, {
		y: 5,
		})
	.to(barBottom, 0.2, {
		y: -5,
		},0)
	.to(barTop, 0.3, {
		rotation:45,
		y:0,
		x:10,
		transformOrigin:"left top",
		},0.15)
	.to(barBottom, 0.3, {
		rotation:-45,
		y:0,
		x:10,
		transformOrigin:"left bottom",
		},0.15)
	.to(barMidL, 0.4, {
		opacity:0,
		scaleX:0,
		transformOrigin:"left",
	}, 0)
	.to(barMidR, 0.4, {
		opacity:0,
		scaleX:0,
		transformOrigin:"right",
	}, 0)
	.to(menuIcon, 0.8, {
		rotation:90,
		}, 0.1)
	.fromTo(circle, 0.6, {
		opacity:0,
		scale: 0,
		transformOrigin: "50% 50%",
			ease: Bounce.easeOut

		}, {
		scale: 1,
		opacity: 1,
		ease: Elastic.easeOut.config(1, 0.8),
		}, 0.5)
	.to([barTop, barBottom], 0.5, {
		scale: 0.6,
		transformOrigin: "50% 50%",
		ease: Power4.easeOut
		}, 0.5);





$("#np-menu").click(function(){
	$(".header-image").toggleClass('hide-header-image');
	if ($(this).hasClass('hide-header-image') ) {
		headerFade();
	} 
	else {
		$(".header-image").css("opacity", 0);
	}	
	$(this).toggleClass('hide-header-image');
});



// Menu Overlay
$('#np-menu').click(function(){	
	if ( $(this).hasClass('toggled') ) {
		MenuIconTimeline.reverse();
	} 	
	else {
		MenuIconTimeline.play();
	}	
	$(this).toggleClass('toggled');	
});


$(document).ready(function () {
  var menu = $('#menu-overlay');
  var menuButton = $('#np-menu');
  var OverlayTimeline = new TimelineMax({paused: true});
  var open = false;
  
  OverlayTimeline

  .to(menu, 1.25, {
    width: '100%',
    /*x: 400,*/
    /*height: '0.1%',*/
    /*transformOrigin:"bottom",*/
    ease: Power4.easeInOut
  }, '-=0.2')

  /*.to(menu, 0.4, {
    height: '100%',
  }, '-=0.2')*/

  .to(menu.find('a'), 0.4, {
    opacity: 1
  })
  
  menuButton.on('click', function () { 
    $(this).toggleClass('active');
    if (open) {
      OverlayTimeline.reverse();
      open = false;
    } else {
      OverlayTimeline.play();
      open = true;
    }
  });
  
  menu.find('a').on('click', function () {
  	open = false;
  	OverlayTimeline.reverse();
    MenuIconTimeline.reverse();
  	$("body").toggleClass("noscroll");
  	$('#np-menu').toggleClass('active');
  	$('#np-menu').toggleClass('toggled');
    $('#np-menu').toggleClass('hide-header-image');
    $(".header-image").toggleClass("hide-header-image");
    headerFade();
  });
});




// Stop Scroll When Menu is Open

$("#np-menu").click(function(){
    $("body").toggleClass("noscroll");
});



//  Homepage Animations




function workpageTimeline(){
var projectItemTitle = $('.project-item h3');
var projectItemImg = $('.project-item img');
  var tlIndex = new TimelineMax({});         
    
    tlIndex
      .from(projectItemTitle, 0.5, {
          /*y: -500,*/
          autoAlpha: 0,
          }, 0)
        .from(projectItemImg, 0.5, {
              autoAlpha: 0,
            })
}


function workpageTimelineReverse(){
var projectItemTitle = $('.project-item h3');
var projectItemImg = $('.project-item img');
  var tlIndex = new TimelineMax({});         
    
    tlIndex
      .to(projectItemTitle, 0.5, {
          /*y: -500,*/
          autoAlpha: 0,
          }, 0)
        .to(projectItemImg, 0.5, {
              autoAlpha: 0,
            })
}





//   Portfolio Page Animations



function TitleTimeline(){
  var projectItemTitle = $('.project-header-wrapper');
  var projectItemImg = $('.image-wrapper');
  /*var projectItemText = $('.project-info-text');*/
  var tl = new TimelineMax({});

  tl
    .from(projectItemTitle, 1, {
    /*y: 150,*/
    autoAlpha: 0,
    }, '+=1')
    /*.from(projectItemImg, 1, {
      autoAlpha: 0,
      scaleY: 0,
    }, '-=0.4')*/
    
 }


function showText() {
	var projectItemText = $('.project-info-text');
	var tl = new TimelineMax({});

	tl
	.from(projectItemText, 1, {
	autoAlpha: 0,
	}, '+=1.5')
}






function showImage() {
    var $colorOverlay = $('.colour-overlay');
    var $whiteOverlay = $('.white-overlay');
    var isAnimated = false;
    if (isAnimated) {
        TweenMax.killAll();
        $whiteOverlay[0].style.transformOrigin = "left 50% 0px";
        isAnimated = false;
    } else {
        isAnimated = true;
    }
    
    TweenMax.to($whiteOverlay, 1, 
        {scaleX: 0, ease:Circ.easeInOut}
    );
    TweenMax.fromTo($colorOverlay, 1, 
        {scaleX: 1},
        {scaleX: 0, ease:Circ.easeInOut, delay: 0.2, onComplete: function() {
            $whiteOverlay[0].style.transformOrigin = "left 50% 0px";           
            isAnimated = false;
        }}
    );
}



/*function hideImage() {
    var $colorOverlay = $('.colour-overlay');
    var $whiteOverlay = $('.white-overlay');
    var isAnimated = false;
    if (isAnimated) {
        TweenMax.killAll();
        $whiteOverlay[0].style.transformOrigin = "left 50% 0px";
        isAnimated = false;
    } else {
        isAnimated = true;
    }
    
    TweenMax.from($whiteOverlay, 1, 
        {scaleX: 0, ease:Power4.easeInOut}
    );
    TweenMax.fromTo($colorOverlay, 1, 
        {scaleX: 1},
        {scaleX: 0, ease:Power4.easeInOut, delay: 0.2, onComplete: function() {
            $whiteOverlay[0].style.transformOrigin = "right";           
            isAnimated = false;
        }}
    );
}*/




function hideImage() {
	var projectItemTitle = $('.project-header-wrapper');
	var $colorOverlay = $('.colour-overlay');
    var $whiteOverlay = $('.white-overlay');
    var projectItemText = $('.project-info-text');
    var imageWrapper = $('.image-wrapper');
    var projectItemQuote = $('.quote-wrapper');
    var tlLeaveFolio = new TimelineMax({});

    var animationTweenType = Circ;

    tlLeaveFolio
	    .to(projectItemTitle, 0.5, {
	    	autoAlpha: 0,
	    	ease: animationTweenType.easeInOut,
	    })    
	    .to($colorOverlay, 0.5,{
	    	scaleX: 1,
	    	/*autoAlpha: 0,*/
	    	ease: animationTweenType.easeInOut,
	    })
	    .to(imageWrapper, 0.2,{
	    	autoAlpha: 0,
	    	ease: animationTweenType.easeInOut,
	    })
	    .to($colorOverlay, 0.5,{	    	
	    	autoAlpha: 0,
	    	ease: animationTweenType.easeInOut,
	    })
	    .to(projectItemText, 0.3,{
	    	autoAlpha: 0,
	    	ease: animationTweenType.easeInOut,
	    }, '-=0.25')
	    .to(projectItemQuote, 0.3,{
	    	autoAlpha: 0,
	    	ease: animationTweenType.easeInOut,
	    }, '-=0.25')
	    

    
}



function showQuote() {
	var projectItemQuote = $('.quote-wrapper');
	var tl = new TimelineMax({});

	tl
	.from(projectItemQuote, 1, {
	autoAlpha: 0,
	}, '+=2')
}






/*    Contact Form Animations     */

function contactAnimation(){

	console.log('contact animation script');
	var contactHeader = $('.contact-header');
	var input1 = $('.input-1');
	var input2 = $('.input-2');
	var input3 = $('.input-3');
	var input4 = $('.input-4');
	var input5 = $('.input-5');
	var formButton = $('.formButton');
	var formButtonText = $('button>span');


	  var tlContact = new TimelineMax({});

	   tlContact
	   .set(formButton, {scaleX: 0});      
	    
	    tlContact
	      .from(contactHeader, 0.5, {
	          /*y: -30,*/
	          autoAlpha: 0,
	          }, 0.5)
	      .from(input1, 0.3, {
	          y: -50,
	          autoAlpha: 0,
	          ease: Power4.easeInOut,
	          }, '-=0.1')
	      .from(input2, 0.3, {
	      	  y: -50,
	          autoAlpha: 0,
	          ease: Power4.easeInOut,
	          }, '-=0.1')
	      .from(input3, 0.3, {
	      	  y: -50,
	          autoAlpha: 0,
	          ease: Power4.easeInOut,
	          }, '-=0.1')
	      .from(input4, 0.3, {
	      	  y: -50,
	          autoAlpha: 0,
	          ease: Power4.easeInOut,
	          }, '-=0.1')
	      .from(input5, 0.3, {
	      	  y: -50,
	          autoAlpha: 0,
	          ease: Power4.easeInOut,
	          }, '-=0.1')
	      .to(formButton, 0.75, {	      	  
	          autoAlpha: 1,
	          transformOrigin:"left",
	          /*width: '100%',*/
	          scaleX: 1,
	          ease: Power4.easeInOut,
	          }, '-=0.1')
	      .from(formButtonText, 0.2, {	      	  
	          autoAlpha: 0,
	          ease: Power4.easeInOut,
	          })

}


function contactAnimationReverse(){

	console.log('contact animation script');
	var contactHeader = $('.contact-header');
	var input1 = $('.input-1');
	var input2 = $('.input-2');
	var input3 = $('.input-3');
	var input4 = $('.input-4');
	var input5 = $('.input-5');
	var formButton = $('.formButton');
	var formButtonText = $('button>span');


	  var tlContact = new TimelineMax({});

	   tlContact
	   .set(formButton, {scaleX: 0});      
	    
	    tlContact
	      .to(formButtonText, 0.2, {	      	  
	          autoAlpha: 0,
	          ease: Power4.easeInOut,
	          })
	      .from(formButton, 0.75, {	      	  
	          autoAlpha: 1,
	          transformOrigin:"left",
	          /*width: '100%',*/
	          scaleX: 1,
	          ease: Power4.easeInOut,
	          }, '-=0.1')
	      .to(input5, 0.2, {
	      	  y: -30,
	          autoAlpha: 0,
	          ease: Power4.easeInOut,
	          }, '-=0.1')
	      .to(input4, 0.2, {
	      	  y: -30,
	          autoAlpha: 0,
	          ease: Power4.easeInOut,
	          }, '-=0.1')
	      .to(input3, 0.2, {
	      	  y: -30,
	          autoAlpha: 0,
	          ease: Power4.easeInOut,
	          }, '-=0.1')
	      .to(input2, 0.2, {
	      	  y: -30,
	          autoAlpha: 0,
	          ease: Power4.easeInOut,
	          }, '-=0.1')
	      .to(input1, 0.2, {
	          y: -30,
	          autoAlpha: 0,
	          ease: Power4.easeInOut,
	          }, '-=0.1')
	      .to(contactHeader, 0.5, {
	          /*y: -30,*/
	          autoAlpha: 0,
	          }, 1.5)

}


          







/*$('.neils-container').imagesLoaded()
  
  .progress( function( instance, image ) {
    var result = image.isLoaded ? 'loaded' : 'broken';
    console.log( 'image is ' + result + ' for ' + image.img.src );

    TitleTimeline();
    showImage();
});*/







/*  CONTACT FORM  */

/*var onReCaptchaLoad = function() {
        var captchaWidgetId = grecaptcha.render( 'myCaptcha', {
          'sitekey' : '6LejEm8UAAAAANebRxWMUodzngiPnAkSs4HnOgSM',  // required
          'theme' : 'light',  // optional
          'callback': 'verifyCallback'  // optional
        });
    };*/





function validateForm(){


      console.log('initiate form code');
                        


      $('#form').validate({
          ignore: ".ignore",
                    rules:{
                        "name":{
                            required:true,
                            maxlength:40    /*<!-- usernames length -->*/
                        },

                        "email":{
                            required:true,
                            email:true,
                            maxlength:100   /*<!-- max length of an email -->*/
                        },
                        
                        "number":{
                            required:true,
                            number:true,
                            minlength:10    /*<!-- min length of phone number -->*/
                        },

                        "message":{
                            required:true
                        },
                        
                        "hiddenRecaptcha":{
                            required: function () {
                            if (grecaptcha.getResponse() == '') {
                                return true;
                            } else {
                                return false;
                            }
                        }
                    }},

                    messages:{
                        "name":{
                            required:"Your name is required."   /* <!-- message for username required -->*/
                        },

                        "email":{
                            required:"Email is required.",    /*<!-- message for email required -->*/
                            email:"Please enter a valid email address."   /*<!-- message for valid email address -->*/
                        },

                        "number":{
                            required:"Phone number is required.",   /*<!-- message for phone number required -->*/
                            number:"Please enter a valid phone number.",    /*<!-- message for valid phone number -->*/
                        },
            
                        "subject":{
                            required:"Subject is required.",   /* <!-- message for subject field -->*/
                        },
            
                        "message":{
                            required:"Please enter your message."  /* <!-- message for entering message field -->*/
                        },
                        
                        "hiddenRecaptcha":{
                            required:"Captcha is required."  /* <!-- message for recaptcha -->*/
                        }},
            
                      errorLabelContainer: '#errors',
                        wrapper:'li',
                      
                        submitHandler: function(form){
                        console.log("submit form");
                        $(form).ajaxSubmit({
                        url: 'mail/mail.php',
                        type: 'POST',
                        data: $('form').serialize(),


                        success: function (data) {
                        console.log("success");

                        /*START ANIMATION TO CONFIRM */


                        $('.xyz').fadeIn("slow").html('Your Message has been sent!'); 
                                  setTimeout(function() {
                        $('.xyz').fadeOut("slow");



                        $('#form')[0].reset();                        
                        /*grecaptcha.reset();*/
                          }, 5000 );
                        }
                    }); 
            }
        })
 };  


  /*  ------  PATCH TO GET THE FORM TO VALIDATE PROPERLY  ---------*/
  jQuery.browser = {};
    (function () {
        jQuery.browser.msie = false;
        jQuery.browser.version = 0;
        if (navigator.userAgent.match(/MSIE ([0-9]+)\./)) {
            jQuery.browser.msie = true;
            jQuery.browser.version = RegExp.$1;
        }
    })();


function revealClients(){
	$(document).ready(function() {  
		$(".client-drop-section h3").click(function() {    
		$(this).children().toggleClass("client-up-dir client-down-dir");
		$(this).siblings().slideToggle(400); 
		});
	});
}



      
